import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import { rhythm, scale } from "../utils/typography"
import Bio from "./bio"

const Layout = ({ location, title, children }) => {
  const data = useStaticQuery(socialLinksQuery)
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
      >
        {title}
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  const socialLinks = data.site.siteMetadata.socialLinks
  const resumeUrl = data.resumeFile.publicURL
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      <footer>
        <hr
          style={{
            marginBottom: rhythm(1),
            marginTop: rhythm(1),
          }}
        />
        <Bio />
        <a
          href={resumeUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Professional Resume
        </a>
        <span>
          {` `}&bull;{` `}
        </span>
        {socialLinks
          ? socialLinks.map((platform, i, arr) => (
            <span key={platform.url}>
              <a
                href={platform.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {platform.name}
              </a>
              {arr.length - 1 !== i && (
                <span>
                  {` `}&bull;{` `}
                </span>
              )}
            </span>
          ))
          : null}
      </footer>
    </div>
  )
}

const socialLinksQuery = graphql`
query SocialLinksQuery {
  site {
    siteMetadata {
      socialLinks {
        name
        url
      }
    }
  }
  resumeFile: file(relativePath: {eq: "resume/Suvish Thoovamalayil.pdf"}) {
    publicURL
  }
}
`

export default Layout
